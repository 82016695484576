.slider {
	width: calc(100% + 16px);
	padding-right: 55px;
	user-select: none;

	@media (min-width: 768px) {
		width: calc(100% + 72px);
		padding-right: 44%;
	}

	@media (min-width: 1280px) {
		width: calc(100% + 80px);
		padding-bottom: 52px;
	}

	&__slide {
		display: flex;
		flex-direction: column;
		color: #fff;
		height: 410px;
		padding: 45px 0 17px 0;
		transition: padding 0.25s linear;
		overflow: hidden;
		border-radius: 0 0 8px 8px;

		@media (min-width: 768px) {
			height: 665px;
			padding: 54px 0;
		}

		@media (min-width: 1280px) {
			height: 416px;
			padding: 45px 0 17px 0;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
		}

		&.swiper-slide-active {
			padding: 0;
		}
	}

	&__up-title {
		font-size: 14px;
		line-height: 24px;
	}

	&__title {
		font-weight: 700;
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 16px;
		letter-spacing: -0.4px;

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 32px;
		}
	}

	&__next {
		position: absolute;
		height: 100%;
		width: 55px;
		top: 0;
		right: -15px;
		z-index: 1;

		@media (min-width: 768px) {
			width: 44%;
			right: -72px;
		}

		@media (min-width: 1280px) {
			width: 33%;
			right: 0;
		}

		@media (min-width: 1440px) {
			width: 40%;
		}
	}

	&__image-box {
		position: relative;
		height: 100%;
	}

	&__fullscreen {
		position: absolute;
		width: 16px;
		height: 16px;
		top: 10px;
		right: 10px;
		background-color: transparent;
		cursor: pointer;

		svg {
			width: 100%;
			height: 100%;
			fill: #fff;
			transition: fill 0.25s linear;
		}

		&:hover {
			svg {
				fill: var(--color-theme, #a40c2d);
			}
		}
	}

	.swiper-button-prev {
		display: none;

		@media (min-width: 1280px) {
			display: flex;
			width: 40px;
			height: 40px;
			top: auto;
			left: 0;
			bottom: 0;
			border-radius: 50%;
			overflow: hidden;
			transition: background-color 0.25s linear;

			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				background-color: rgba(#fff, 0.3);
				filter: blur(4px);
			}

			&::after {
				font-size: 14px;
				font-weight: 700;
				color: #fff;
			}

			&:hover {
				background-color: #fff;

				&::after {
					color: var(--color-theme, #a40c2d);
				}
			}
		}
	}

	.swiper-button-next {
		display: none;

		@media (min-width: 1280px) {
			display: flex;
			width: 40px;
			height: 40px;
			top: auto;
			left: 48px;
			bottom: 0;
			border-radius: 50%;
			overflow: hidden;
			transition: background-color 0.25s linear;

			&::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				background-color: rgba(#fff, 0.3);
				filter: blur(4px);
			}

			&::after {
				font-size: 14px;
				font-weight: 700;
				color: #fff;
			}

			&:hover {
				background-color: #fff;

				&::after {
					color: var(--color-theme, #a40c2d);
				}
			}
		}
	}
}
