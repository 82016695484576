.main {
	position: relative;
	flex: 1 0 auto;
	width: 100%;
	padding: 0 16px;
	overflow: hidden;

	@media (min-width: 768px) {
		padding: 0 72px 0 32px;
	}

	@media (min-width: 1280px) {
		padding: 0 80px 0 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__timer {
		margin-bottom: 15px;

		&.timer--v-2 {
			//padding-left: 28px;

			@media (min-width: 768px) {
				//padding-left: 0;
				margin-bottom: 22px;
			}

			@media (min-width: 1280px) {
				margin-bottom: 5px;
			}
		}
	}

	&__form {
		@media (min-width: 768px) {
			max-width: 625px;
		}

		@media (min-width: 1280px) {
			width: 100%;
			max-width: 600px;
		}
	}

	&__title {
		font-weight: 800;
		font-size: 20px;
		line-height: 27px;
		color: #fff;
		margin-bottom: 40px;

		@media (min-width: 768px) {
			max-width: 500px;
			font-size: 32px;
			line-height: 48px;
			letter-spacing: -0.6px;
			margin-bottom: 36px;
		}

		@media (min-width: 1280px) {
			max-width: 605px;
			font-size: 40px;
			line-height: 56px;
		}

		&.main__title--v-2 {
			//padding-left: 28px;

			@media (min-width: 768px) {
				//padding-left: 0;
			}

			@media (min-width: 1280px) {
				margin-bottom: 54px;
			}

			@media (min-width: 1440px) {
				margin-bottom: 36px;
			}
		}
	}

	&__form-and-slider {
		position: relative;
		margin-bottom: 56px;

		@media (min-width: 768px) {
			margin-bottom: 40px;
		}

		@media (min-width: 1280px) {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		&.main__form-and-slider--with-right-image {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;

			@media (min-width: 1280px) {
				flex-direction: row;
			}
		}
	}

	&__form-image-box {
		width: 245px;
		margin-bottom: -200px;

		@media (min-width: 768px) {
			width: 370px;
			margin-bottom: -300px;
		}

		@media (min-width: 1280px) {
			width: 216px;
			margin-top: -70px;
			margin-right: 10%;
			margin-bottom: 0;
		}

		img {
			width: 100%;
		}
	}

	& &__slider {
		position: relative;
		margin-bottom: 40px;

		@media (min-width: 1280px) {
			width: 30.5%;
			margin: -170px 0 0 0;
		}

		@media (min-width: 1440px) {
			width: 31%;
			max-width: 430px;
		}

		.slider {
			@media (min-width: 1280px) {
				width: 100%;
				padding-right: 33%;
			}

			@media (min-width: 1440px) {
				padding-right: 40%;
			}
		}
	}

	&__expert {
		@media (min-width: 768px) {
			display: none;
		}
	}

	&__background-image-box {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(#000, 0.2);
		}
	}

	&.main--v-2 {
		@media (min-width: 1280px) {
			padding-right: 0;
		}

		@media (min-width: 1440px) {
			padding-right: 0;
			padding-left: 48px;
		}
	}

	&.main--wa-bot {
		.main__title {
			@media (min-width: 1280px) {
				display: block;
				max-width: 735px;
			}
		}
	}
}
