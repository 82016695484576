.background {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;

	&::after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
		background-color: rgba(#000, 0.2);
	}

	canvas {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}

	img {
		width: 100%;
		max-width: 100%;
		position: relative;
		z-index: 0;
		opacity: 0;
	}
}
