.timer {
	color: #fff;

	&__title {
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 8px;
	}

	&__list {
		display: flex;
	}

	&__item {
		width: 90px;
		margin-right: 8px;

		@media (min-width: 768px) {
			width: 82px;
		}
	}

	&__box {
		position: relative;
		width: 100%;
		height: 72px;
		font-weight: 700;
		font-size: 32px;
		line-height: 32px;
		border-radius: 15px;
		background-color: rgba(21, 34, 52, 0.15);
		margin-bottom: 2px;
		overflow: hidden;

		div {
			position: absolute;
			width: 100%;
			height: 200%;
			animation: down 1s forwards ease-out;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 50%;
			}
		}
	}

	&__text {
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
	}

	&.timer--not-init {
		.timer__list {
			opacity: 0;
		}
	}

	&.timer--v-2 {
		@media (min-width: 768px) {
			display: flex;
			align-items: center;
		}

		.timer__title {
			@media (min-width: 768px) {
				margin-bottom: 0;
				margin-right: 16px;
			}
		}

		.timer__item {
			display: flex;
			align-items: center;
			width: auto;
			margin-right: 12px;
		}

		.timer__box {
			width: 32px;
			height: 32px;
			font-size: 14px;
			border-radius: 8px;
			margin-right: 8px;
			margin-bottom: 0;
		}
	}
}

@keyframes down {
	from {
		transform: translateY(-50%);
	}

	to {
		transform: translateY(0);
	}
}
