.footer {
    position: relative;
    width: 100%;
    font-size: 12px;
    line-height: 19px;
    color: #fff;
    padding: 0 16px 20px;

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        padding: 0 32px 20px;
    }

    @media (min-width: 1280px) {
        padding: 0 48px 12px;
    }

    &__copyright {
        margin-bottom: 4px;

        @media (min-width: 768px) {
            margin-bottom: 0;
            margin-right: 26px;
        }
    }

    &__privacy-policy {
        display: inline-flex;
        color: #fff;
        margin-bottom: 16px;

        @media (min-width: 768px) {
            margin-bottom: 0;
            margin-right: auto;
        }
    }

    &__social-box {
        display: flex;
    }

    &__social {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 8px;
        transition: background-color 0.25s linear;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(#fff, 0.3);
            filter: blur(4px);
        }

        svg {
            width: 20px;
            height: 16px;
            fill: #fff;
            transition: fill 0.25s linear;
        }

        @media (min-width: 1280px) {
            &:hover {
                background-color: #fff;

                svg {
                    fill: var(--color-theme, #a40c2d);
                }
            }
        }
    }
}
