.slider-full {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	&__slide {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		color: #fff;
		padding: 80px 16px;

		@media (min-width: 768px) {
			padding: 80px 30px;
		}

		@media (min-width: 1024px) {
			padding: 80px 40px;
		}

		@media (min-width: 1280px) {
			padding: 90px 105px;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}

		&::after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-image: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
		}
	}

	&__up-title {
		position: relative;
		font-size: 14px;
		line-height: 24px;
		z-index: 1;
	}

	&__title {
		position: relative;
		font-weight: 800;
		font-size: 20px;
		line-height: 27px;
		margin-bottom: 5px;
		z-index: 1;
	}

	.swiper-button-prev {
		display: flex;
		width: 40px;
		height: 40px;
		top: auto;
		left: auto;
		right: 72px;
		bottom: 16px;
		border-radius: 50%;
		overflow: hidden;
		transition: background-color 0.25s linear;

		@media (min-width: 768px) {
			top: 50%;
			bottom: auto;
			right: auto;
			left: 40px;
			transform: translateY(-50%);
		}

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba(#fff, 0.3);
			filter: blur(4px);
		}

		&::after {
			font-size: 14px;
			font-weight: 700;
			color: #fff;
		}

		&:hover {
			background-color: #fff;

			&::after {
				color: var(--color-theme, #a40c2d);
			}
		}
	}

	.swiper-button-next {
		display: flex;
		width: 40px;
		height: 40px;
		top: auto;
		right: 16px;
		bottom: 16px;
		border-radius: 50%;
		overflow: hidden;
		transition: background-color 0.25s linear;

		@media (min-width: 768px) {
			top: 50%;
			bottom: auto;
			right: 40px;
			transform: translateY(-50%);
		}

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba(#fff, 0.3);
			filter: blur(4px);
		}

		&::after {
			font-size: 14px;
			font-weight: 700;
			color: #fff;
		}

		&:hover {
			background-color: #fff;

			&::after {
				color: var(--color-theme, #a40c2d);
			}
		}
	}

	.swiper-pagination-fraction {
		position: absolute;
		left: 16px;
		bottom: 20px;
		font-size: 20px;
		line-height: 32px;
		color: #fff;
		z-index: 1;

		@media (min-width: 768px) {
			left: auto;
			right: 30px;
			bottom: 80px;
		}

		@media (min-width: 1024px) {
			right: 40px;
		}

		@media (min-width: 1280px) {
			right: 104px;
		}
	}

	&__close {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 20px;
		right: 20px;
		background-color: transparent;
		z-index: 1;
		cursor: pointer;

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 20px;
			height: 2px;
			background-color: #ccc;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: background-color 0.25s linear;
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:hover {
			&::before,
			&::after {
				background-color: var(--color-theme, #a40c2d);
			}
		}
	}
}
