.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 54px;
	font-weight: 700;
	font-size: 14px;
	line-height: 22px;
	color: #fff;
	border-radius: 10px;
	cursor: pointer;
	background-color: var(--color-theme, #d0103a);
	transition: background-color 0.25s, filter 0.25s linear;

	@media (min-width: 1280px) {
		&:hover {
			filter: brightness(85%);
		}
	}
}
