.modal-thanks {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	animation: opacity 0.25s linear;

	&__layout {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(#000, 0.4);
	}

	&__content {
		position: relative;
		width: 100%;
		height: 100%;
		max-width: 360px;
		max-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		overflow: hidden;
		color: #333;
		padding: 0 65px;
		border-radius: 20px;
	}

	&__background {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		span {
			position: absolute;
			width: 100%;
			height: 100%;
			backdrop-filter: blur(8px);
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		position: relative;
		font-weight: 700;
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 8px;
		text-align: center;
	}

	&__text {
		position: relative;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
	}

	&__close {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 14px;
		right: 14px;
		padding: 0;
		background-color: transparent;
		z-index: 1;
		cursor: pointer;

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 20px;
			height: 3px;
			background-color: #ccc;
			transition: background-color 0.25s linear;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		@media (min-width: 1280px) {
			&:hover {
				&::before,
				&::after {
					background-color: #d0103a;
				}
			}
		}
	}
}

@keyframes opacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
