.characteristics {
	margin-bottom: 40px;

	@media (min-width: 768px) {
		display: inline-flex;
	}

	&__item {
		width: 100%;
		color: #fff;
		margin-bottom: 10px;

		@media (min-width: 768px) {
			margin-right: 30px;
		}

		span {
			display: block;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 5px;
		}

		b {
			white-space: nowrap;
			line-height: 25px;

			@media (min-width: 768px) {
				font-size: 20px;
				line-height: 32px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;

			@media (min-width: 768px) {
				margin-right: 0;
			}
		}
	}
}
