.main-box {
	position: relative;
	flex: 1 0 auto;
	width: 100%;
	padding: 0 16px;
	overflow: hidden;
	margin-bottom: 30px;

	@media (min-width: 768px) {
		padding: 0 72px 0 32px;
	}

	@media (min-width: 1280px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 80px 0 48px;
	}

	&__content {
		@media (min-width: 1280px) {
			display: flex;
			align-items: center;
		}
	}

	&__timer {
		margin-bottom: 15px;

		&.timer--v-2 {
			//padding-left: 28px;

			@media (min-width: 768px) {
				//padding-left: 0;
				margin-bottom: 22px;
			}

			@media (min-width: 1280px) {
				margin-bottom: 5px;
			}
		}
	}

	&__form {
		@media (min-width: 768px) {
			max-width: 625px;
		}

		@media (min-width: 1280px) {
			width: 100%;
			max-width: 458px;
			margin-left: auto;
		}
	}

	&__title {
		font-weight: 800;
		font-size: 20px;
		line-height: 27px;
		color: #fff;
		margin-bottom: 40px;

		@media (min-width: 768px) {
			max-width: 500px;
			font-size: 32px;
			line-height: 48px;
			letter-spacing: -0.6px;
			margin-bottom: 36px;
		}

		@media (min-width: 1280px) {
			max-width: 605px;
			font-size: 40px;
			line-height: 56px;
		}

		&.main__title--v-2 {
			//padding-left: 28px;

			@media (min-width: 768px) {
				//padding-left: 0;
			}

			@media (min-width: 1280px) {
				margin-bottom: 54px;
			}

			@media (min-width: 1440px) {
				margin-bottom: 36px;
			}
		}
	}

	&__expert {
		@media (min-width: 768px) {
			display: none;
		}
	}
}
