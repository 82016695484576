.messengers {
	&__text {
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 12px;
	}

	&__list {
		@media (min-width: 768px) {
			display: flex;
		}
	}

	&__item {
		width: 100%;
		margin-bottom: 8px;

		label {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			line-height: 22px;
			color: #fff;
			padding: 7px;
			border-radius: 20px;
			border: 1px solid #fff;
			transition: opacity 0.25s linear;
			opacity: 0.4;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}

		input {
			display: none;

			&:checked + label {
				opacity: 1;
			}
		}

		@media (min-width: 768px) {
			width: auto;
			min-width: 110px;
			margin-bottom: 0;
			margin-right: 8px;
		}
	}
}
