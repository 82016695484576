.whatsapp {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 1280px) {
		padding: 0 10% 0 0;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	&__image-box {
		width: 245px;

		@media (min-width: 768px) {
			width: 370px;
		}

		@media (min-width: 1280px) {
			width: 216px;
			margin-top: -70px;
		}

		img {
			width: 100%;
		}
	}

	&__info {
		color: #fff;
		background-color: rgba(21, 34, 52, 0.35);
		border-radius: 20px;
		backdrop-filter: blur(5px);
		padding: 24px;
		margin-top: -200px;

		@media (min-width: 768px) {
			padding: 32px 40px;
			margin-top: -300px;
		}

		@media (min-width: 1280px) {
			max-width: 665px;
			margin-top: 0;
		}
	}

	&__info-title {
		font-size: 18px;
		line-height: 29px;
		margin-bottom: 20px;
	}

	&__info-steps {
		margin-bottom: 40px;

		span {
			display: block;
			font-size: 14px;
			line-height: 22px;
		}
	}

	&__link {
		display: block;
		width: 100%;
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		color: #fff;
		border-radius: 10px;
		background-color: #d0103a;
		padding: 16px 10px;
		text-align: center;
		margin-bottom: 12px;
	}

	&__agreement {
		font-size: 12px;
		line-height: 19px;
		color: rgba(#fff, 0.5);

		a {
			color: rgba(#fff, 0.5);
		}
	}
}

