* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'OpenSansKf';
  src: local('OpenSansKf Semibold'), local('OpenSans-Semibold'), url('./assets/fonts/OpenSans-Medium.woff2') format('woff2'), url('./assets/fonts/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'OpenSansKf';
  src: local('OpenSansKf Bold'), local('OpenSans-Bold'), url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'), url('./assets/fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'OpenSansKf';
  src: local('OpenSansKf Extrabold'), local('OpenSans-Extra-Bold'), url('./assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('./assets/fonts/OpenSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, 'OpenSansKf', Arial, sans-serif;
  font-weight: 500;
	background-color: #333;
}

#root {
	position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body-fixed {
  //overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  border: none;
  padding: 0;
}

:root {
  --color-theme: rgb(208, 16, 58);
}
