.pagination {
	color: #fff;
	margin-bottom: 5px;

	@media (min-width: 1280px) {
		position: relative;
		display: flex;
		align-items: center;
	}

	&__hidden-box {
		@media (min-width: 1280px) {
			position: absolute;
			width: 100%;
		}
	}

	&__list {
		margin-left: 0;
	}

	&__text {
		display: none;

		@media (min-width: 1280px) {
			display: inline-block;
			width: 110px;
			font-size: 14px;
			line-height: 22px;
			margin-right: 12px;
		}
	}

	&__item {
		position: relative;
		width: 56px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 5px;
		border-radius: 50%;
		padding: 4px;
		border: 1px solid rgba(#fff, 0.5);
		cursor: pointer;

		img {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			object-fit: cover;
		}

		svg {
			position: absolute;
			width: 56px;
			height: 56px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-90deg);
			stroke-width: 1.5px;
			stroke-dasharray: 173 173;
			stroke-dashoffset: 173;
			fill: none;
			stroke: #d0103a;
		}

		&.swiper-slide-thumb-active:not(.swiper-slide-duplicate) {
			svg {
				animation: ring 7s linear forwards;
			}
		}
	}
}

@keyframes ring {
	from {
		stroke-dashoffset: 173;
	}

	to {
		stroke-dashoffset: 0;
	}
}
