.expert {
	display: flex;
	color: #fff;

	&__image {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 10px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__name {
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
	}

	&__position {
		font-weight: 500;
		max-width: 200px;
		font-size: 14px;
		line-height: 22px;

		@media (min-width: 1024px) {
			max-width: 100%;
		}
	}

	&.expert--main {
		margin-bottom: 16px;

		.expert__info {
			padding-top: 5px;
		}

		.expert__image {
			width: 72px;
			height: 72px;
			margin-right: 15px;
		}
	}
}
