.modal {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	animation: opacity 0.25s linear;

	&__layout {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(#000, 0.4);
	}

	&__content {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: #fff;
		overflow: auto;

		@media (min-width: 768px) {
			display: flex;
			height: 485px;
			max-width: 865px;
		}
	}

	&__close {
		position: absolute;
		width: 20px;
		height: 20px;
		top: 14px;
		right: 14px;
		padding: 0;
		background-color: transparent;
		z-index: 1;
		cursor: pointer;

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: 20px;
			height: 3px;
			background-color: #ccc;
			transition: background-color 0.25s linear;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		@media (min-width: 1280px) {
			&:hover {
				&::before,
				&::after {
					background-color: var(--color-theme, #d0103a);
				}
			}
		}
	}

	&__expert-box {
		background-color: #f1f1f3;
		padding: 16px;

		@media (min-width: 768px) {
			width: 45%;
			height: 100%;
		}
	}

	&__expert {
		.expert__image {
			width: 56px;
			height: 56px;
			margin-right: 16px;

			@media (min-width: 768px) {
				width: 290px;
				height: 290px;
				margin-bottom: 24px;
				margin-right: 0;
			}
		}

		.expert__info {
			@media (min-width: 768px) {
				text-align: center;
			}
		}

		.expert__name {
			color: #333;
		}

		.expert__position {
			color: #888;
		}

		@media (min-width: 768px) {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	&__form {
		.form {
			padding: 10px 16px;

			@media (min-width: 768px) {
				padding: 38px 33px 20px;
			}
		}

		.form__bg-box {
			display: none;
		}

		.form__title {
			color: #333;
		}

		.form__text {
			color: #888;
			padding-right: 0;
		}

		.form__messengers {
			color: #333;
		}

		.messengers__item {
			label {
				color: #888;
			}

			input:checked + label {
				background-color: #f1f1f3;
				color: #333;
			}
		}

		.form__form-group {
			@media (min-width: 768px) {
				flex-direction: column;
			}
		}

		.form__form-input {
			input {
				background-color: #f1f1f3;
			}

			@media (min-width: 768px) {
				width: 100%;
				margin-bottom: 12px;
			}
		}

		.form__phone {
			&.react-tel-input {
				@media (min-width: 768px) {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}

		.form__form-error {
			@media (min-width: 768px) {
				left: auto;
				right: 5px;
			}
		}

		.form__form-button {
			width: 100%;
		}

		.form__agreement {
			color: #666;
			opacity: 1;

			a {
				color: var(--color-theme, #d0103a);
			}
		}

		.form__info {
			display: none;
		}

		@media (min-width: 768px) {
			width: 55%;
		}
	}

	&__svg-box {
		display: none;

		@media (min-width: 768px) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		img {
			width: 200px;
			height: 200px;
		}
	}
}

@keyframes opacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
